<template>
  <v-app id="app">
    <original-header v-if="!isAuthProcessing" />
    <router-view v-if="!isAuthProcessing" v-show="!isSubmitting" />
    <original-footer v-if="!isAuthProcessing" />
    <processing v-if="isProcessing || isSubmitting" />
    <telop v-if="telop.show" />
  </v-app>
</template>

<script>
import OriginalHeader from '@/components/common/header.vue'
import OriginalFooter from '@/components/common/footer.vue'
import Processing from '@/components/common/processing.vue'
import Telop from '@/components/common/telop'

export default {
  components: { OriginalHeader, OriginalFooter, Processing, Telop },
  mounted () {
    // authの監視
    this.$store.dispatch('auth/onAuth')

    // メンテナンス情報の監視を開始
    this.$store.dispatch('maintenances/onMaintenance')
  },
  computed: {
    /**
     * @return {Boolean} 初期処理中かどうか
     */
    isAuthProcessing () {
      return this.$store.getters.isAuthProcessing
    },
    /**
     * @return {Boolean} 同期処理が必要な情報を取得中かどうか
     */
    isProcessing () {
      return this.$store.getters.isProcessing
    },
    /**
     * @return {Boolean} フォーム等で情報を更新中かどうか
     */
    isSubmitting () {
      return this.$store.getters.isSubmitting
    },
    /**
     * @return {Object} テロップの情報
     */
    telop () {
      return this.$store.getters['telop']
    }
  }
}

</script>

<style lang="scss">
@import '@/assets/sass/size.scss';
@import '@/assets/sass/color.scss';

html {
  font-size: 10px !important;
}

#app {
  font-family: 'Noto Sans JP', 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: $black_color;
  background-color: white;
}

a,
.v-application a {
  color: $black_color !important;
  text-decoration: none;
  outline: none;
}

.v-application p {
  margin: 0;
}

.active-font {
  font-weight: bold;
  color: $orange_color !important;
}

.float-right {
  float: right;
}

// for vuetify
.v-toolbar__content {
  padding: 0 !important;
}
</style>
