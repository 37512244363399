import { firestore } from '@/firebase'
import router from '@/router'

const getDefaultState = () => {
  return {
    // 一度取得していれば再取得の必要ないので、フラグを用意しておく
    hasGot: false,
    // フォロー中の情報一覧（自分がフォローしているもののみ）
    follows: []
  }
}

const state = getDefaultState()

const getters = {
  /**
   * @param {Object} state 暗黙的に受け取るstate
   * @return {Boolean} フォロー中情報を取得済みかどうか
   */
  hasGot: state => state.hasGot,
  /**
   * @param {Object} state 暗黙的に受け取るstate
   * @return {Object[]} フォロー情報の一覧
   */
  follows: state => state.follows,
  /**
   * @param {Object} state 暗黙的に受け取るstate
   * @param {String} uid ユーザーID
   * @return {Object} 指定ユーザーのフォロー情報
   */
  follow: state => uid => state.follows.find(follow => uid === follow.follow) || null,
  /**
   * @param {Object} state 暗黙的に受け取るstate
   * @param {String} uid ユーザーID
   * @return {Boolean} 指定ユーザーをフォロー済みかどうか
   */
  isFollow: state => uid => state.follows.filter(follow => uid === follow.follow).length === 1
}

const mutations = {
  /**
   * フォロー中情報が取得済みかどうか
   * @param {Object} state 暗黙的に受け取るstate
   * @param {Boolean} hasGot 取得済みかどうか
   */
  setHasGot: (state, hasGot) => {
    state.hasGot = hasGot
  },
  /**
   * フォロー中情報をstateにセット
   * @param {Object} state 暗黙的に受け取るstate
   * @param {Object} follow フォロー情報
   */
  setFollow: (state, follow) => {
    const index = state.follows.findIndex(doc => doc.fid === follow.fid)
    index === -1 ? state.follows.push(follow) : state.follows.splice(index, 1, follow)
  },
  /**
   * フォロー中情報をstateから外す
   * @param {Object} state 暗黙的に受け取るstate
   * @param {String} fid followsのドキュメントID
   */
  unsetFollow: (state, fid) => {
    state.follows = state.follows.filter(follow => follow.fid !== fid)
  },
  /**
   * stateのリセットを行う
   *
   * @param {Object} state 暗黙的に受け取るstate
   */
  resetState: state => {
    state = Object.assign(state, getDefaultState())
  }
}

const actions = {
  /**
   * フォロー中情報の取得
   * @param {String} uid 自分自身のユーザーID
   */
  async getFollows ({ commit }, uid) {
    try {
      const snapshot = await firestore
        .collection('follows')
        .where('uid', '==', uid)
        .get()
      snapshot.forEach(doc => {
        commit('setFollow', Object.assign({ fid: doc.id }, doc.data()))
      })
      commit('setHasGot', true)
    } catch {
      router.push({ name: 'error' })
    }
  },
  /**
   * フォローの開始
   * @param {Object} payload 引数
   * @param {String} payload.uid 自分のユーザーID
   * @param {String} payload.follow フォロー先のユーザーID
   */
  async addFollow ({ commit }, payload) {
    try {
      const createdAt = new Date()
      const doc = await firestore
        .collection('follows')
        .add({
          uid: payload.uid,
          follow: payload.follow,
          createdAt: createdAt
        })
      commit('setFollow', {
        fid: doc.id,
        uid: payload.uid,
        follow: payload.follow,
        createdAt: createdAt
      })
    } catch {
      router.push({ name: 'error' })
    }
  },
  /**
   * フォローの解除
   * @param {String} fid followsのドキュメントID
   */
  async deleteFollow ({ commit }, fid) {
    try {
      await firestore
        .collection('follows')
        .doc(fid)
        .delete()
      commit('unsetFollow', fid)
    } catch {
      router.push({ name: 'error' })
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
