import { firebase, provider } from '@/firebase'
import router from '@/router'

const getDefaultState = () => {
  return {
    uid: null
  }
}

const state = getDefaultState()

const getters = {
  /**
   * @param {Object} state 暗黙的に受け取るstate
   * @return {String} ユーザーID
   */
  uid: state => state.uid
}

const mutations = {
  /**
   * ユーザーIDをstateにセット
   * @param {Object} state 暗黙的に受け取るstate
   * @param {String} uid ユーザーIDをstateにセット
   */
  setUID: (state, uid) => {
    state.uid = uid
  },
  /**
   * stateのリセットを行う
   *
   * @param {Object} state 暗黙的に受け取るstate
   */
  resetState: state => {
    state = Object.assign(state, getDefaultState())
  }
}
const actions = {
  /**
   * authの監視
   */
  onAuth ({ commit, getters, rootGetters, dispatch }) {
    firebase.auth().onAuthStateChanged(async user => {
      commit('setAuthProcessing', true, { root: true })

      // auth情報をセット
      commit('setUID', user ? user.uid : null)

      if (user) {
        // 初期に必要な情報の取得
        await Promise.all([
          dispatch('uidUname/getUname', user.uid, { root: true }),
          dispatch('users/getUser', user.uid, { root: true }),
          dispatch('collections/getCollections', user.uid, { root: true })
        ])

        // 退会処理かどうかの情報取得
        // 退会処理の場合、Cancel.vueで作成した退会処理情報が保存されている
        const cancelUID = sessionStorage.getItem('cancelUID')

        if (cancelUID) {
          // 退会処理を行ったアカウントと再認証アカウントが同じかどうか
          const isSameAccount = cancelUID === getters.uid

          // 退会処理
          // 退会処理を行ったGoogleアカウントと異なるアカウントで再認証した場合は、ログアウト
          isSameAccount ? await dispatch('cancel') : await dispatch('signout')

          // セッション情報の削除
          sessionStorage.removeItem('cancelUID')

          // ページ遷移先の設定
          commit('setRedirectURL', '/', { root: true })

          // 退会処理を行ったGoogleアカウントと異なるアカウントで再認証した場合は、テロップを表示
          if (!isSameAccount) {
            const msg = '退会するアカウントが違います。\n再度ログインして退会をしてください。'
            commit('setTelop', { show: true, msg: msg, type: 'error' }, { root: true })
          }
        }
      }

      commit('setAuthProcessing', false, { root: true })

      // 初回アクセスの場合はリダイレクトさせる
      const redirectPath = rootGetters.redirectPath
      await router.replace({ path: redirectPath, query: { auth: new Date().getTime() } })
    })
  },
  /**
   * サインイン
   * @param {String} sns SNS認証のプロバイダー名 google, facebook, twitter
   */
  async signin ({ commit }, sns) {
    try {
      await firebase.auth().signInWithRedirect(provider[sns])
    } catch {
      router.push({ name: 'error' })
    }
  },
  /**
   * サインアウト
   * @return {Object} status：成功の有無（success or error）、error：エラーコード（エラー時のみ）
   */
  async signout ({ commit, dispatch }) {
    try {
      await firebase.auth().signOut()

      // storeの不要な情報をリセットする
      dispatch('resetState', null, { root: true })

      return { status: 'success' }
    } catch (error) {
      return { status: 'error', error: error.code }
    }
  },
  /**
   * 退会処理を行う
   */
  async cancel ({ commit, getters, rootGetters, dispatch }) {
    try {
      // firestoreの情報の削除
      // 全ての削除が成功してからauth情報は削除
      await dispatch('collections/deleteAllData', null, { root: true })
      await dispatch('cancelMembership')
    } catch {
      router.push({ name: 'error' })
    }
  },
  /**
   * 退会、ユーザーIDのリセット
   * 注意：退会処理を行うには最近ログインしている必要がある
   */
  async cancelMembership ({ commit }) {
    try {
      await firebase.auth().currentUser.delete()
      commit('setUID', null)
    } catch {
      router.push({ name: 'error' })
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
