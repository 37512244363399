<template>
  <div :class="parentClassName" v-if="!isProcessing">
    <div :class="parentClassName + '__main'">
      <v-img class="top__main__background" :src="require('@/assets/img/background.jpg')" />
      <div class="top__main__info">
        <img :class="parentClassName + '__main__info__logo'" src="@/assets/img/logo.svg"/>
        <h2 :class="parentClassName + '__main__info__catchcry'" >私の情報をQRコードでキュレーション</h2>
        <v-btn :class="parentClassName + '__main__info__btn'" outlined rounded color="orange lighten-1" @click="signin()">利用開始</v-btn>
        <p :class="parentClassName + '__main__info__warning'">Googleアカウントを登録することで利用できます</p>
      </div>
    </div>
    <h1 :class="parentClassName + '__title'">QRコードで自分をキュレーション！<br>あなたの情報をいつでもどこでも簡単に伝えよう</h1>
    <h2 :class="parentClassName + '__subtitle'">「紹介にかかる時間を一瞬に」<br>キュレーションされたQRコードを読み取ってもらうだけで、あなたの情報が一瞬で伝わる！</h2>
    <div :class="parentClassName + '__how'" v-for="useage in useages" :key="useage.icon">
      <div :class="parentClassName + '__how__img'" color="orange lighten-1">
        <v-icon color="white">{{ useage.icon }}</v-icon>
      </div>
      <h2 :class="parentClassName + '__how__title'">{{ useage.title }}</h2>
      <p :class="parentClassName + '__how__detail'">{{ useage.detail }}</p>
    </div>
    <div :class="parentClassName + '__smalltitle'">
      <p><a target="_blank" href="https://docs.google.com/document/d/1QJfAasQZvv04Ymf7sk544DZ3IgTMVQbBoN48y5gMDOI/edit?usp=sharing">利用規約</a></p>
      <p><a target="_blank" href="https://docs.google.com/document/d/1OeFs1jzojWV-EFtcRmC54NtRioAiPvEbBxwlCE17cuQ/edit?usp=sharing">プライバシーポリシー</a></p>
      <p>運営会社</p>
    </div>
    <div :class="parentClassName + '__link'">
      <a target="_blank" href="https://nukumo.link">一般社団法人nukumo</a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'top',
  data () {
    return {
      // 使い方をまとめた配列
      useages: [
        { title: 'アップロード', icon: 'cloud_upload', detail: 'QRコードに登録するURLを入力しましょう。後で探しやすいよう自由にカテゴリーをつけられます。' },
        { title: '管理', icon: 'assignment', detail: 'アップロードした情報を一覧で確認しましょう。登録したカテゴリーごとに表示することができます。' },
        { title: '共有', icon: 'group', detail: '共有したい情報を選択すると、QRコードが表示されます。それを読み取ってもらって、情報を共有しましょう！！' }
      ],
      // ログインエラーが存在するかどうか
      isError: false
    }
  },
  mounted () {
    this.$store.commit('setProcessing', false)
  },
  computed: {
    /**
     * @return {Boolean} 同期処理が必要な情報を取得中かどうか
     */
    isProcessing () {
      return this.$store.getters.isProcessing
    },
    /**
     * @return {Boolean} スマホかどうか
     */
    isSP () {
      return /Android|webOS|iPhone|iPad|iPod|BlackBerry|BB|PlayBook|IEMobile|Windows Phone|Kindle|Silk|Opera Mini/i.test(
        navigator.userAgent
      )
    },
    /**
     * @return {String} 親のクラス名
     */
    parentClassName () {
      return this.isSP ? 'top--sp' : 'top--pc'
    },
    /**
     * @return {String} サイトのURL
     */
    site () {
      return window.location.href
    }
  },
  methods: {
    /**
     * サインイン処理
     */
    signin () {
      this.$store.commit('setSubmitting', true)
      this.$store.dispatch('auth/signin', 'google')
      this.$router.push({ name: 'list' })
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/sass/size.scss';
@import '@/assets/sass/color.scss';

.top {
  width: 100vw;
  text-align: center;
  &__main {
    position: relative;
    width: 100%;
    height: 100vh;
    &__background {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
    &__info {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 90%;
      -webkit-transform: translateY(-50%) translateX(-50%);
      transform: translateY(-50%) translateX(-50%);
      &__logo {
        margin: 0 auto;
        object-fit: contain;
      }
      &__catchcry {
        color: white;
      }
      &__warning {
        color: white;
      }
    }
  }
  &__how {
    width: 80%;
    margin: 0 auto;
    text-align: center;
    &__img {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      vertical-align: middle;
      background-color: $orange_color;
      border-radius: 50%;
    }
  }
  &--sp {
    @extend .top;
    &__main {
      @extend .top__main;
      &__info {
        @extend .top__main__info;
        &__logo {
          @extend .top__main__info__logo;
          width: 200px;
        }
        &__catchcry {
          @extend .top__main__info__catchcry;
          margin: 10px 0;
          font-size: 1.4rem;
        }
        &__btn {
          margin-top: 30px;
        }
        &__warning {
          @extend .top__main__info__warning;
          margin: 10px;
          font-size: 1.1rem;
        }
      }
    }
    &__title {
      margin-top: 70px;
      font-size: 1.4rem;
    }
    &__subtitle {
      width: 90%;
      margin: 0 auto;
      margin-top: 10px;
      font-size: 1.2rem;
      font-weight: normal;
    }
    &__smalltitle {
      margin: 0;
      margin-top: 70px;
      text-decoration: underline $black_color;
      p:last-child {
        margin-bottom: 0;
      }
    }
    &__how {
      @extend .top__how;
      margin-top: 40px;
      &__img {
        @extend .top__how__img;
        width: 100px;
        height: 100px;
        i {
          font-size: 45px;
        }
      }
      &__title {
        margin-top: 10px;
        font-size: 1.4rem;
      }
      &__detail {
        margin-top: 20px;
        font-size: 1.2rem;
        text-align: left;
      }
    }
    &__link {
      margin-top: 12px;
      margin-bottom: 30px;
    }
  }
  &--pc {
    @extend .top;
    &__main {
      @extend .top__main;
      &__info {
        @extend .top__main__info;
        &__logo {
          @extend .top__main__info__logo;
          width: 300px;
        }
        &__catchcry {
          @extend .top__main__info__catchcry;
          margin: 15px 0;
          font-size: 1.7rem;
        }
        &__qr {
          margin-top: 70px;
        }
        &__warning {
          @extend .top__main__info__warning;
          margin: 20px;
          font-size: 1.4rem;
        }
      }
    }
    &__title {
      margin-top: 120px;
      font-size: 3.6rem;
    }
    &__subtitle {
      width: 90%;
      margin: 0 auto;
      margin-top: 50px;
      font-size: 2rem;
      font-weight: normal;
    }
    &__smalltitle {
      margin: 0;
      margin-top: 150px;
      font-size: 1.5rem;
      text-decoration: underline $black_color;
      p:last-child {
        margin-bottom: 0;
      }
    }
    &__how {
      @extend .top__how;
      display: inline-block;
      width: 25%;
      margin: 0 auto;
      margin-top: 100px;
      vertical-align: top;
      &__img {
        @extend .top__how__img;
        width: 143px;
        height: 143px;
        i {
          font-size: 60px;
        }
      }
      &__title {
        margin-top: 37px;
        font-size: 2.1rem;
      }
      &__detail {
        width: 80%;
        margin: 0 auto;
        margin-top: 42px;
        font-size: 1.4rem;
        text-align: left;
      }
    }
    &__link {
      margin-top: 12px;
      margin-bottom: 30px;
      font-size: 1.6rem;
    }
  }
}
</style>
