import Vue from 'vue'
import Vuetify from 'vuetify'
import VueClipboard from 'vue-clipboard2'
import VueQriously from 'vue-qriously'
import VueGtm from '@gtm-support/vue2-gtm'
import App from './App.vue'
import router from './router'
import store from './store'
import './registerServiceWorker'
import '@babel/polyfill'
import 'whatwg-fetch'
import 'vuetify/dist/vuetify.min.css'
import 'material-design-icons-iconfont/dist/material-design-icons.css'

Vue.config.productionTip = false

/**
 * @see https://www.npmjs.com/package/vue-qriously
 */
Vue.use(VueQriously)

/**
 * @see https://github.com/Inndy/vue-clipboard2
 */
Vue.use(VueClipboard)

/**
 * setting of vuetify which is material design lib
 * @see https://vuetifyjs.com
 */
const opts = {}
Vue.use(Vuetify, { iconfont: 'md' })

/**
 * setting of gtm
 * @see @see https://github.com/gtm-support/vue-gtm/tree/vue2-gtm
 */
Vue.use(VueGtm, { id: 'GTM-52LQFVG' })

new Vue({
  router,
  store,
  vuetify: new Vuetify(opts),
  render: function (h) {
    return h(App)
  }
}).$mount('#app')
