<template>
  <header v-if="isDisplay" class="header">
    <v-btn text class="header__btn" v-if="type.includes('back')" @click="back()">
      <v-icon class="header__btn__icon">arrow_back</v-icon>
    </v-btn>
    <v-img v-if="type.includes('logo')" class="header__logo" :src="require('@/assets/img/logo.svg')" contain />
    <p class="header__title" v-if="type.includes('title')">{{ title }}</p>
  </header>
</template>

<script>
// ヘッダーを表示させないページ名
const NOT_DISPLAY = [
  'top',
  'maintenance',
  'redirect',
  'error',
  'notfound'
]

// ヘッダーの詳細
// type: back, logo, title
const HEADER_DETAIL = {
  list: {
    type: ['logo']
  },
  code: {
    type: ['logo']
  },
  code_add: {
    type: ['back', 'title'],
    title: 'QRコードの登録'
  },
  code_edit: {
    type: ['back', 'title'],
    title: 'QRコードの編集'
  },
  profile: {
    type: ['back', 'title'],
    title: 'プロフィール'
  },
  profile_edit_info: {
    type: ['back', 'title'],
    title: 'プロフィール編集'
  },
  profile_edit_link: {
    type: ['back', 'title'],
    title: 'リンク編集'
  },
  profile_code: {
    type: ['back']
  },
  friends: {
    type: ['back', 'title'],
    title: 'フォロー中のユーザー'
  },
  cancel: {
    type: ['back', 'title'],
    title: '退会'
  },
  menu: {
    type: ['logo']
  }
}

export default {
  computed: {
    /**
     * @return {Boolean} アップロードボタンを表示させるかどうか
     */
    isDisplay () {
      return this.$route.name ? !NOT_DISPLAY.includes(this.$route.name) : false
    },
    /**
     * @return {String} router.jsで定義された名前
     */
    pageName () {
      return this.$route.name
    },
    /**
     * @return {String} ヘッダーのタイトル
     */
    title () {
      return this.pageName && HEADER_DETAIL[this.pageName]['title'] ? HEADER_DETAIL[this.pageName]['title'] : ''
    },
    /**
     * @return {String} ヘッダーのタイプ
     */
    type () {
      return this.pageName && HEADER_DETAIL[this.pageName]['type'] ? HEADER_DETAIL[this.pageName]['type'] : []
    }
  },
  methods: {
    /**
     * 前のページに戻る
     * 直リンクなどで前のページが存在しない場合はQRコード一覧へ遷移させる
     */
    back () {
      if (window.history.length > 2) {
        this.$router.go(-1)
      } else {
        this.$router.push({ name: 'list' })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/sass/color.scss';
@import '@/assets/sass/size.scss';

.header {
  position: fixed;
  top: 0;
  left: 50%;
  z-index: $header_zindex;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100vw;
  max-width: $max_width;
  height: $header_height;
  text-align: center;
  background-color: $white_color;
  transform: translate(-50%, 0);
  &__btn {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 10px;
    margin: auto;
    &.v-btn:not(.v-btn--round).v-size--default {
      min-width: auto;
      height: 24px;
      padding: 0;
      color: $black_color;
    }
    &__icon {
      width: 24px;
      height: 24px;
      &.v-icon {
        font-size: 2.4rem;
      }
    }
  }
  &__logo {
    width: 120px;
    max-height: calc(#{$header_height} - 10px);
    margin: 0 auto;
  }
  &__title {
    max-width: 70%;
    margin: 0 auto;
    font-size: 1.4rem;
    font-weight: bold;
  }
}
</style>
