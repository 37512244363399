import { firestore } from '@/firebase'
import router from '@/router'

const getDefaultState = () => {
  return {
    // ユーザー名一覧
    // { uid: uname, uid: uname, ... }
    unames: {}
  }
}

const state = getDefaultState()

const getters = {
  /**
   * @param {Object} state 暗黙的に受け取るstate
   * @param {String} uname ユーザー名
   * @return {String} ユーザーID（存在しない場合はnull）
   */
  uid: state => uname => {
    for (const key in state.unames) {
      if (state.unames[key] === uname) return key
    }
    return null
  },
  /**
   * @param {Object} state 暗黙的に受け取るstate
   * @return {String} 取得済みの全ユーザー名
   */
  unames: state => state.unames,
  /**
   * @param {Object} state 暗黙的に受け取るstate
   * @param {String} uid ユーザーID
   * @return {String} ユーザー名
   */
  uname: state => uid => state.unames[uid] || null
}

const mutations = {
  /**
   * ユーザー名をstateにセット
   * @param {Object} state 暗黙的に受け取るstate
   * @param {Object} payload 引数の情報
   * @param {String} payload.uid セットしたいユーザー名に紐づくユーザーID
   * @param {String} payload.uname セットしたいユーザー名
   */
  setUname: (state, payload) => {
    state.unames = Object.assign({}, state.unames, { [payload.uid]: payload.uname })
  },
  /**
   * stateのリセットを行う
   *
   * @param {Object} state 暗黙的に受け取るstate
   */
  resetState: state => {
    state = Object.assign(state, getDefaultState())
  }
}
const actions = {
  /**
   * ユーザー名の取得
   * @param {String} uid ユーザーID
   */
  async getUname ({ commit }, uid) {
    try {
      const snapshot = await firestore
        .collection('uid_uname')
        .where('uid', '==', uid)
        .get()
      // もし1つ以上あったらバグなので、エラーに遷移させる
      if (snapshot.size > 1) throw new Error('uidに紐づくユーザー名が複数あります')

      let uname = null
      snapshot.forEach(doc => uname = doc.id)
      if (uname) commit('setUname', { uid: uid, uname: uname })
    } catch {
      router.push({ name: 'error' })
    }
  },
  /**
   * ユーザーIDの取得
   * @param {String} uname ユーザー名
   * @return {String} ユーザーID（存在しない場合はnull）
   */
  async getUID ({ commit }, uname) {
    try {
      const doc = await firestore
        .collection('uid_uname')
        .doc(uname)
        .get()
      const uid = doc.exists ? doc.data().uid : null
      if (uid) commit('setUname', { uid: uid, uname: uname })
      return uid
    } catch {
      router.push({ name: 'error' })
    }
  },
  /**
   * ユーザー名が存在するかどうか
   * @param {String} uname 確認するユーザー名
   * @return {Boolean} 存在するかどうか
   */
  async existUname ({ commit }, uname) {
    try {
      const doc = await firestore
        .collection('uid_uname')
        .doc(uname)
        .get()
      return doc.exists
    } catch {
      router.push({ name: 'error' })
    }
  },
  /**
   * ユーザー名の作成
   * @param {Object} payload 引数の情報
   * @param {String} payload.uname 作成したいユーザー名
   * @param {String} payload.uid 作成するユーザー名
   */
  async createUname ({ commit }, payload) {
    try {
      await firestore
        .collection('uid_uname')
        .doc(payload.uname)
        .set({
          uid: payload.uid,
          createdAt: new Date()
        })
      commit('setUname', { uid: payload.uid, uname: payload.uname })
    } catch {
      router.push({ name: 'error' })
    }
  },
  /**
   * ユーザー名の削除
   * @param {String} uname 削除したいユーザー名
   */
  async deleteUname ({ commit }, uname) {
    try {
      await firestore
        .collection('uid_uname')
        .doc(uname)
        .delete()
    } catch {
      router.push({ name: 'error' })
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
