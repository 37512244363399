import { firestore } from '@/firebase'
import router from '@/router'

const getDefaultState = () => {
  return {
    // リンク情報の一覧
    // { ユーザーID: [ {}, ... ], ... }
    links: {}
  }
}

const state = getDefaultState()

const getters = {
  /**
   * @param {Object} state 暗黙的に受け取るstate
   * @param {String} uid ユーザーID
   * @return {Object} リンク情報
   */
  links: state => uid => state.links[uid] || []
}

const mutations = {
  /**
   * リンク情報をstateにセット
   * @param {Object} state 暗黙的に受け取るstate
   * @param {Object} payload 引数の情報
   * @param {String} payload.uid ユーザーID
   * @param {String} payload.lid リンクID
   * @param {Object} payload.object リンク情報のオブジェクト（削除の場合はnull）
   */
  setLink: (state, payload) => {
    // リンクが一つも存在しない場合は、空配列をまず代入
    if (!state.links[payload.uid]) state.links[payload.uid] = []

    // objectの有無で追加更新・削除を判断
    if (payload.object) {
      const index = state.links[payload.uid].findIndex(link => link.lid === payload.lid)
      if (index > -1) {
        // state内に既にリンクが存在すれば更新
        Object.keys(payload.object).forEach(key => {
          state.links[payload.uid][index][key] = payload.object[key]
        })
      } else {
        // state内にリンクが存在しない場合追加
        state.links[payload.uid].push(payload.object)
      }
    } else {
      state.links[payload.uid] = state.links[payload.uid].filter(link => link.lid !== payload.lid)
    }

    // computedで検知させるためにobject全体を更新
    const newLinks = state.links[payload.uid]
    state.links = Object.assign({}, state.links, { [payload.uid]: newLinks })
  },
  /**
   * stateのリセットを行う
   *
   * @param {Object} state 暗黙的に受け取るstate
   */
  resetState: state => {
    state = Object.assign(state, getDefaultState())
  }
}

const actions = {
  /**
   * リンク情報の作成
   * @param {Object} payload リンク情報
   * @param {String} payload.uid リンク情報を持つユーザーのID
   * @param {Object} payload.params リンク情報
   * @return {String} リンクID
   */
  async createLink ({ commit }, payload) {
    try {
      const doc = await firestore.collection('links').add(payload.params)
      const object = Object.assign(payload.params, { lid: doc.id })
      commit('setLink', { uid: payload.uid, lid: doc.id, object: object })
      return doc.id
    } catch {
      router.push({ name: 'error' })
    }
  },
  /**
   * リンク情報の更新
   * @param {Object} payload 更新するリンク情報
   * @param {String} payload.uid 更新するリンクを持つユーザーのID
   * @param {String} payload.lid 更新対象のリンク情報のID
   * @param {Object} payload.params 更新情報
   */
  async updateLink ({ commit }, payload) {
    try {
      await firestore
        .collection('links')
        .doc(payload.lid)
        .update(payload.params)
      commit('setLink', { uid: payload.uid, lid: payload.lid, object: payload.params })
    } catch {
      router.push({ name: 'error' })
    }
  },
  /**
   * リンク情報の削除
   * @param {Object} payload 引数
   * @param {String} payload.uid ユーザーID
   * @param {String} payload.lid リンクID
   */
  async deleteLink ({ commit }, payload) {
    try {
      await firestore
        .collection('links')
        .doc(payload.lid)
        .delete()
      commit('setLink', { uid: payload.uid, lid: payload.lid, object: null })
    } catch {
      router.push({ name: 'error' })
    }
  },
  /**
   * リンク情報の取得
   * @param {String} uid ユーザーID
   */
  async getLinks ({ commit }, uid) {
    try {
      const snapshot = await firestore
        .collection('links')
        .where('uid', '==', uid)
        .orderBy('name', 'desc')
        .get()
      snapshot.forEach(doc => {
        commit('setLink', { uid: uid, object: Object.assign(doc.data(), { lid: doc.id }) })
      })
    } catch {
      router.push({ name: 'error' })
    }
  },
  /**
   * 指定のコレクションを持つリンク集を取得する
   * @param {Object} payload 取得するコレクション情報
   * @param {String} payload.uid 指定のコレクションを持つユーザーのID
   * @param {String} payload.cid 指定のコレクションのID
   * @return {Object} 指定のコレクションを持つリンク集（存在しない場合nullを返す）
   */
  async getLinkContainsCID ({ commit }, payload) {
    try {
      const snapshot = await firestore
        .collection('links')
        .where('uid', '==', payload.uid)
        .where('cids', 'array-contains', payload.cid)
        .get()
      const link = !snapshot.empty ? Object.assign(snapshot.docs[0].data(), { lid: snapshot.docs[0].id }) : null
      return link
    } catch {
      router.push({ name: 'error' })
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
