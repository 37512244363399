import { firestore } from '@/firebase'
import router from '@/router'

const getDefaultState = () => {
  return {
    // 最新のメンテナンス情報
    maintenance: null
  }
}

const state = getDefaultState()

const getters = {
  /**
   * @param {Object} state 暗黙的に受け取るstate
   * @return {Object} 最新のメンテナンス情報
   */
  maintenance: state => state.maintenance
}

const mutations = {
  /**
   * メンテナンス情報をstateにセット
   * @param {Object} state 暗黙的に受け取るstate
   * @param {Object} maintenance メンテナンス情報のオブジェクト
   */
  setMaintenance: (state, maintenance) => {
    state.maintenance = maintenance
  },
  /**
   * stateのリセットを行う
   *
   * @param {Object} state 暗黙的に受け取るstate
   */
  resetState: state => {
    state = Object.assign(state, getDefaultState())
  }
}
const actions = {
  /**
   * メンテナンス情報の監視
   */
  onMaintenance ({ commit, state }) {
    firestore.collection('maintenances').orderBy('createdAt', 'desc').limit(1).onSnapshot(querySnapshot => {
      for (const change of querySnapshot.docChanges()) {
        // 過去のメンテナンス情報の取得
        const beforeMaintenance = state.maintenance
        // 現在のメンテナンス情報に更新
        const afterMaintenance = change.doc.data()
        commit('setMaintenance', afterMaintenance)

        if (!afterMaintenance.endAt) {
          // 終了時刻が設定されてないので、メンテナンス開始
          commit('setRedirectURL', '/maintenance', { root: true })
          router.push({ name: 'maintenance' })

          // onAuthが動かないので、ここでprocessingをfalseにしておく
          commit('setAuthProcessing', false, { root: true })
        } else if (beforeMaintenance && !beforeMaintenance.endAt && afterMaintenance.endAt) {
          // 変更前が終了時刻なしかつ最新がある状態なので、メンテナンス終了
          // App.vueのmountedを再読み込みさせるためにリロードさせる
          window.location.href = '/'
        }
      }
    }, () => {})
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
