<template>
  <v-layout v-if="isDisplay" align-center justify-space-around class="footer">
    <div class="footer__menu" @click="$router.push({ name: 'list' })">
      <v-icon :class="isPage('list') ? 'footer__menu__icon--active' : 'footer__menu__icon'">home</v-icon>
    </div>
    <!-- <div class="footer__menu">
      <v-icon :class="isPage('search') ? 'footer__menu__icon--active' : 'footer__menu__icon'">search</v-icon>
    </div> -->
    <div class="footer__menu" @click="$router.push({ name: 'code_add' })">
      <v-icon :class="isPage('code_add') ? 'footer__menu__icon--active' : 'footer__menu__icon'">add_circle_outline</v-icon>
    </div>
    <!-- <div class="footer__menu">
      <v-icon :class="isPage('profile') ? 'footer__menu__icon--active' : 'footer__menu__icon'">supervisor_account</v-icon>
    </div> -->
    <div class="footer__menu" @click="$router.push({ name: 'menu' })">
      <v-icon :class="isPage('menu') ? 'footer__menu__icon--active' : 'footer__menu__icon'">menu</v-icon>
    </div>
  </v-layout>
</template>

<script>
// フッターを表示させないページ名
const NOT_DYSPLAY = [
  'top',
  'profile_code',
  'redirect',
  'maintenance',
  'error',
  'notfound'
]

export default {
  computed: {
    /**
     * @return {Boolean} フッターを表示させるかどうか
     */
    isDisplay () {
      return this.$route.name ? !NOT_DYSPLAY.includes(this.$route.name) : false
    },
    /**
     * @param {String} page ページ名
     * @return {Booelan} 表示中のページが引数名のページかどうか
     */
    isPage () {
      return page => this.$route.name ? this.$route.name.indexOf(page) === 0 : false
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/assets/sass/color.scss';
@import '@/assets/sass/size.scss';
@import '@/assets/sass/fontfamily.scss';

.footer {
  position: fixed;
  bottom: 0;
  left: 50%;
  width: 100vw;
  max-width: $max_width;
  height: $footer_height;
  background-color: rgba(255, 255, 255, 0.9);
  transform: translate(-50%, 0);
  &__menu {
    &__icon {
      font-family: $material-outlined;
      font-size: 2.4rem;
      &--active {
        @extend .footer__menu__icon;
        &.theme--light.v-icon {
          color: $orange_color;
        }
      }
    }
  }
}
</style>
