import { firestore } from '@/firebase'
import router from '../../router'

const state = {
}

const getters = {
}

const mutations = {
}

const actions = {
  /**
   * ディープリンク登録
   * @param {Object} payload 引数の情報
   * @param {String} payload.cid 対応するcid
   * @param {String} payload.url 登録URLの全文
   * @param {String} payload.deeplink.profileId profileId
   * @param {Date} payload.deeplink.createdAt 作成日時（新規登録時のみ）
   * @param {Date} payload.deeplink.updatedAt 更新日時
   */
  async createDeeplink ({ dispatch, commit }, payload) {
    try {
      // Facebook専用の処理
      const info = await dispatch('functions/getFacebookInfo', { url: payload.url }, { root: true })
      payload.deeplink.schema = info.schema
      payload.deeplink.profileId = info.id

      // ディープリンク情報を登録
      await firestore
        .collection('collections')
        .doc(payload.cid)
        .collection('deeplinks')
        .doc(payload.cid)
        .set(payload.deeplink)
    } catch {
      router.push({ name: 'error' })
    }
  },
  /**
   * ディープリンク取得
   * @param {Object} payload 引数の情報
   * @param {String} payload.cid 対応するcid
   * @return {Object} ディープリンク生成用ID情報（空の場合はnull）
   */
  async getDeeplink ({ commit }, payload) {
    try {
      // ディープリンク情報を取得
      const doc = await firestore
        .collection('collections')
        .doc(payload.cid)
        .collection('deeplinks')
        .doc(payload.cid)
        .get()
      return doc.exists ? doc.data() : null
    } catch {
      router.push({ name: 'error' })
    }
  },
  /**
   * ディープリンク更新
   * @param {Object} payload 引数の情報
   * @param {String} payload.cid 対応するcid
   */
  async updateDeeplink ({ dispatch, commit }, payload) {
    try {
      // Facebook専用の処理
      if (payload.url) {
        const info = await dispatch('functions/getFacebookInfo', { url: payload.url }, { root: true })
        payload.deeplink.schema = info.schema
        payload.deeplink.profileId = info.id
      }

      // ディープリンク情報を更新
      await firestore
        .collection('collections')
        .doc(payload.cid)
        .collection('deeplinks')
        .doc(payload.cid)
        .update(payload.deeplink)
    } catch {
      router.push({ name: 'error' })
    }
  },
  /**
   * ディープリンク削除
   * @param {Object} payload 引数の情報
   * @param {String} payload.cid 対応するcid
   */
  async deleteDeeplink ({ commit }, payload) {
    try {
      // ProfileIdを削除
      await firestore
        .collection('collections')
        .doc(payload.cid)
        .collection('deeplinks')
        .doc(payload.cid)
        .delete()
    } catch {
      router.push({ name: 'error' })
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
