import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'

import functions from './modules/functions'
import cloudstorage from './modules/cloudstorage'
import auth from './modules/auth'
import deeplinks from './modules/deeplinks'
import uidUname from './modules/uidUname'
import users from './modules/users'
import links from './modules/links'
import collections from './modules/collections'
import follows from './modules/follows'
import maintenances from './modules/maintenances'

Vue.use(Vuex)

const modules = { functions, cloudstorage, auth, uidUname, users, links, collections, deeplinks, follows, maintenances }

export default new Vuex.Store({
  modules: modules,
  state: {
    MAX_LENGTH: {
      PROFILE: {
        name: 50,
        uname: 30,
        position: 20,
        profile: 160
      }
    },
    isFirstAccess: true,
    isAuthProcessing: true,
    isProcessing: true,
    isSubmitting: false,
    redirectPath: null,
    telop: {
      show: false,
      msg: '',
      type: ''
    },
    active: 'ALL'
  },
  getters: {
    /**
     * @param {Object} state 暗黙的に受け取るstate
     * @return {Boolean} 同期処理が必要な情報を取得中かどうか
     */
    isProcessing: state => state.isProcessing,
    /**
     * @param {Object} state 暗黙的に受け取るstate
     * @return {Boolean} フォーム等で情報を更新中かどうか
     */
    isSubmitting: state => state.isSubmitting,
    /**
     * @param {Object} state 暗黙的に受け取るstate
     * @return {Boolean} Auth処理中かどうか
     */
    isAuthProcessing: state => state.isAuthProcessing,
    /**
     * @param {Object} state 暗黙的に受け取るstate
     * @return {Boolean} 最初のアクセスかどうか
     */
    isFirstAccess: state => state.isFirstAccess,
    /**
     * @param {Object} state 暗黙的に受け取るstate
     * @return {String} 遷移先のURL
     */
    redirectPath: state => state.redirectPath,
    /**
     * @param {Object} state 暗黙的に受け取るstate
     * @return {Object} テロップの情報
     */
    telop: state => state.telop,
    /**
     * @param {Object} state 暗黙的に受け取るstate
     * @return {Number} 無料枠のQRコード数
     */
    FREE_QR_UPLOAD_NUM: state => state.FREE_QR_UPLOAD_NUM,
    /**
     * @param {Object} state 暗黙的に受け取るstate
     * @return {Objcet} プロフィールの格納できる文字数の最大値
     */
    MAX_LENGTH: state => state.MAX_LENGTH,
    /**
     * @param {Object} state 暗黙的に受け取るstate
     * @return {String} 選択中のカテゴリー
     */
    active: state => state.active
  },
  mutations: {
    /**
     * @param {Object} state 暗黙的に受け取るstate
     * @param {Boolean} isAuthProcessing Auth処理中かどうか
     */
    setAuthProcessing: (state, isAuthProcessing) => {
      state.isAuthProcessing = isAuthProcessing
      if (!isAuthProcessing && state.isFirstAccess) state.isFirstAccess = false
    },
    /**
     * @param {Object} state 暗黙的に受け取るstate
     * @param {Boolean} isProcessing 同期処理が必要な情報を取得中かどうか
     */
    setProcessing: (state, isProcessing) => {
      state.isProcessing = isProcessing
      if (state.isSubmitting) state.isSubmitting = false
    },
    /**
     * @param {Object} state 暗黙的に受け取るstate
     * @param {Boolean} isSubmitting フォーム等で情報を更新中かどうか
     */
    setSubmitting: (state, isSubmitting) => {
      state.isSubmitting = isSubmitting
    },
    /**
     * @param {Object} state 暗黙的に受け取るstate
     * @param {Boolean} isFirstAccess 初期アクセスかどうか
     */
    setFirstAccess: (state, isFirstAccess) => {
      state.isFirstAccess = isFirstAccess
    },
    /**
     * @param {Object} state 暗黙的に受け取るstate
     * @param {String} path アクセスされたURL
     */
    setRedirectURL: (state, path) => {
      state.redirectPath = path
    },
    /**
     * @param {Object} state 暗黙的に受け取るstate
     * @param {Object} telop テロップの情報
     * @param {Boolean} telop.show テロップ表示の有無
     * @param {String} telop.msg テロップの内容
     * @param {String} telop.type テロップの色 success, warning, error
     */
    setTelop: (state, telop) => {
      state.telop = telop
    },
    /**
     * 選択したカテゴリーをstateにセット
     * @param {Object} state 暗黙的に受け取るstate
     * @param {String} active セットするアクティブ中のカテゴリー
     */
    setActive: (state, active) => {
      state.active = active
    }
  },
  actions: {
    /**
     * activeなカテゴリーが削除されていた場合activeをALLへ変更
     */
    resetActive ({ commit, getters }) {
      const active = getters['active']
      const categories = getters['collections/categories']
      if (!categories.includes(active)) {
        commit('setActive', 'ALL')
      }
    },
    /**
     * 全モジュールのstoreをリセットする
     */
    resetState: ({ commit }) => {
      Object.keys(modules).forEach(key => {
        if (Object.keys(modules[key].state).length !== 0) commit(key + '/resetState')
      })
    }
  },
  plugins: [createPersistedState({
    key: 'QRation',
    paths: ['auth.uid'],
    storage: window.sessionStorage
  })]
})
