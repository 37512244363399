import { firestore } from '@/firebase'
import router from '@/router'

const getDefaultState = () => {
  return {
    // 名前順にソートしたカテゴリー一覧
    // [ 'hoge1', 'hoge2', ... ]
    categories: [],
    // コレクション情報
    // [{ cid:String, categories: [String], collectionName: String,
    //  deeplink: String, uid: String, createdAt: Date(), updatedAt: Date(), url: String }, ...]
    collections: [],
    // 他のユーザーのコレクション
    profileCollections: [],
    // プロフィール画面で表示するユーザーのコレクション群
    // { uid: {lid: コレクション情報, lid: コレクション情報, ...}, ... }
    displayProfileCollections: {}
  }
}

const state = getDefaultState()

const getters = {
  /**
   * @param {Object} state 暗黙的に受け取るstate
   * @return {String[]} カテゴリー一覧
   */
  categories: state => state.categories,
  /**
   * @param {Object} state 暗黙的に受け取るstate
   * @param {String} cid 取得したいコレクションのドキュメントID
   * @return {Object | null} cidが一致するコレクションがある場合：コレクション情報、ない場合：null
   */
  collection: state => cid => state.collections.find(collection => collection.cid === cid) || null,
  /**
   * @param {Object} state 暗黙的に受け取るstate
   * @return {Object[]} コレクション一覧
   */
  collections: state => state.collections,
  /**
   * @param {Object} state 暗黙的に受け取るstate
   * @param {String} cid 取得したい他ユーザーのコレクションのドキュメントID
   * @return {Object | null} cidが一致するコレクションがある場合：コレクション情報、ない場合：null
   */
  profileCollection: state => cid => state.profileCollections.find(collection => collection.cid === cid) || null,
  /**
   * @param {Object} state 暗黙的に受け取るstate
   * @param {String} uid コレクション群を取得したいユーザーのID
   * @return {Object | null} uidが一致するコレクション群がある場合：コレクション群情報、ない場合：null
   */
  displayProfileCollections: state => uid => state.displayProfileCollections[uid] || null
}

const mutations = {
  /**
   * カテゴリーの配列をstateにセット
   * @param {Object} state 暗黙的に受け取るstate
   * @param {String[]} categories セットするカテゴリー一覧
   */
  setCategories: (state, categories) => {
    // カテゴリの重複を除去
    state.categories = Array.from(new Set(state.categories.concat(categories))).sort()
  },
  /**
   * コレクションからカテゴリを再設定
   * @param {Object} state 暗黙的に受け取るstate
   */
  resetCategory: state => {
    // コレクションの持っているカテゴリ一覧を取得
    const categories = state.collections.reduce((categories, collection) => categories.concat(collection.categories), [])

    // 重複のあるカテゴリーを削除してからソート
    state.categories = Array.from(new Set(categories)).sort()
  },
  /**
   * コレクションのオブジェクトをstateにセット
   * @param {Object} state 暗黙的に受け取るstate
   * @param {Object} newCollection セットするコレクションのオブジェクト
   */
  setCollection: (state, newCollection) => {
    const index = state.collections.findIndex(collection => collection.cid === newCollection.cid)
    if (index !== -1) {
      // 対象コレクションは取得済みであるため上書き
      state.collections.splice(index, 1, newCollection)
    } else {
      state.collections.push(newCollection)
    }
  },
  /**
   * コレクションをstateから削除
   * @param {Object} state 暗黙的に受け取るstate
   * @param {String} cid 削除するコレクションのドキュメントID
   */
  deleteCollection: (state, cid) => {
    state.collections = state.collections.filter(collection => collection.cid !== cid)
  },
  /**
   * コレクションのオブジェクト一覧をstateにセット
   * @param {Object} state 暗黙的に受け取るstate
   * @param {Object[]} collections セットするコレクションのオブジェクト一覧
   */
  setCollections: (state, collections) => {
    state.collections = collections
  },
  /**
   * 他ユーザーのコレクションのオブジェクトをstateにセット
   * @param {Object} state 暗黙的に受け取るstate
   * @param {Object} collection セットするコレクションのオブジェクト
   */
  setProfileCollection: (state, collection) => {
    state.profileCollections.push(collection)
  },
  /**
   * 該当ユーザーのコレクション群のオブジェクトをstateにセット
   * @param {Object} state 暗黙的に受け取るstate
   * @param {Object} payload 引数
   * @param {String} payload.uid 該当ユーザーのUID
   * @param {Object} payload.displayProfileCollections 該当ユーザーのコレクション群
   */
  setDisplayCollections: (state, payload) => {
    state.displayProfileCollections = Object.assign({}, state.displayProfileCollections, { [payload.uid]: payload.displayProfileCollections })
  },
  /**
   * stateのリセットを行う
   *
   * @param {Object} state 暗黙的に受け取るstate
   */
  resetState: state => {
    state = Object.assign(state, getDefaultState())
  }
}

const actions = {
  /**
   * コレクション情報の登録
   * @param {Object} payload コレクション情報の登録情報
   * @return {String} 作成したcid
   */
  async createCollection ({ commit }, payload) {
    try {
      const doc = await firestore
        .collection('collections')
        .add({
          uid: payload.uid,
          collectionName: payload.collectionName,
          url: payload.url,
          categories: payload.categories,
          createdAt: new Date(),
          updatedAt: new Date()
        })
      payload.cid = doc.id
      commit('setCollection', payload)
      commit('setCategories', payload.categories)
      return doc.id
    } catch {
      router.push({ name: 'error' })
    }
  },
  /**
   * コレクション情報の更新
   * @param {Object} payload 更新するコレクション情報
   */
  async editCollection ({ commit }, payload) {
    try {
      await firestore
        .collection('collections')
        .doc(payload.cid)
        .update({
          collectionName: payload.collectionName,
          url: payload.url,
          categories: payload.categories,
          updatedAt: new Date()
        })
      commit('setCollection', payload)
      commit('setCategories', payload.categories)
    } catch {
      router.push({ name: 'error' })
    }
  },
  /**
   * コレクション情報の削除
   * @param {String} cid 削除するコレクションのドキュメントID
   */
  async deleteCollection ({ commit }, cid) {
    try {
      await firestore.collection('collections').doc(cid).delete()
      commit('deleteCollection', cid)
    } catch {
      router.push({ name: 'error' })
    }
  },
  /**
   * 全URLの取得
   * @param {String} uid ユーザーID
   * @return {Object[]} 全コレクション情報
   */
  async getCollections ({ commit }, uid) {
    try {
      const snapshot = await firestore
        .collection('collections')
        .where('uid', '==', uid)
        .orderBy('collectionName', 'desc')
        .get()
      const results = []
      const categories = []
      snapshot.forEach(doc => {
        results.push(Object.assign(doc.data(), { cid: doc.id }))
        doc.data().categories.forEach(category => categories.push(category))
      })

      // カテゴリーの名前でソート
      categories.sort((a, b) => {
        if (a > b) return 1
        if (a < b) return -1
        return 0
      })

      // カテゴリー、コレクションのセット
      commit('setCategories', categories)
      commit('setCollections', results)

      return results
    } catch {
      router.push({ name: 'error' })
    }
  },
  /**
   * コレクションの取得
   * @param {String} cid コレクションID
   */
  async getCollection ({ commit, rootGetters }, cid) {
    try {
      const doc = await firestore
        .collection('collections')
        .doc(cid)
        .get()
      if (doc.exists) {
        const collection = Object.assign({ cid: doc.id }, doc.data())
        const uid = rootGetters['auth/uid']
        // 自分のコレクションの場合と他ユーザーのコレクションでセット先を分岐
        commit(uid === collection.uid ? 'setCollection' : 'setProfileCollection', collection)
      }
    } catch {
      router.push({ name: 'error' })
    }
  },
  /**
   * 全データの削除
   */
  async deleteAllData ({ commit, getters }) {
    try {
      await Promise.all(
        getters.collections.map(collection => firestore.collection('collections').doc(collection.cid).delete())
      )
    } catch {
      router.push({ name: 'error' })
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
