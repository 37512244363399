import Vue from 'vue'
import Router from 'vue-router'
import Top from '@/views/Top.vue'

import store from '@/store'
import { firebase } from '@/firebase'

/**
 * vue-router v3.1.0から、同一画面に遷移するとコンソールエラーが発生するようになった
 * push関数にエラーハンドリングを追加して、デフォルトの関数を上書きすることで対応
 * @see https://github.com/vuejs/vue-router/issues/2881#issuecomment-520554378
 */
const originalPush = Router.prototype.push
Router.prototype.push = function push (location, onResolve, onReject) {
  if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
  return originalPush.call(this, location).catch(err => err)
}

const originalReplace = Router.prototype.replace
Router.prototype.replace = function replace (location, onResolve, onReject) {
  if (onResolve || onReject) return originalReplace.call(this, location, onResolve, onReject)
  return originalReplace.call(this, location).catch(err => err)
}

Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'top',
      component: Top
    },
    {
      path: '/list',
      name: 'list',
      component: loadComponent('List.vue'),
      meta: { requiresAuth: true }
    },
    {
      path: '/code/add',
      name: 'code_add',
      component: loadComponent('CodeAdd.vue'),
      meta: { requiresAuth: true }
    },
    {
      path: '/code/:cid',
      name: 'code',
      component: loadComponent('Code.vue'),
      meta: { requiresAuth: true }
    },
    {
      path: '/code/:cid/edit',
      name: 'code_edit',
      component: loadComponent('CodeEdit.vue'),
      meta: { requiresAuth: true }
    },
    {
      path: '/profile/edit/info',
      name: 'profile_edit_info',
      component: loadComponent('ProfileEditInfo.vue'),
      meta: { requiresAuth: true }
    },
    {
      path: '/profile/edit/link',
      name: 'profile_edit_link',
      component: loadComponent('ProfileEditLink.vue'),
      meta: { requiresAuth: true }
    },
    // ローカル用.firebase-hostingでfunctionsに飛ぶ.
    {
      path: '/profile/:uname',
      name: 'profile',
      component: loadComponent('Profile.vue'),
      meta: { autoTransition: true }
    },
    // ogpリダイレクト用
    {
      path: '/profile/ogp/:uname',
      name: 'profile',
      component: loadComponent('Profile.vue'),
      meta: { autoTransition: true }
    },
    {
      path: '/profile/:uname/code',
      name: 'profile_code',
      component: loadComponent('ProfileCode.vue'),
      meta: { autoTransition: true }
    },
    {
      path: '/friends',
      name: 'friends',
      component: loadComponent('Friends.vue'),
      meta: { requiresAuth: true }
    },
    {
      path: '/menu',
      name: 'menu',
      component: loadComponent('Menu.vue'),
      meta: { requiresAuth: true }
    },
    {
      path: '/redirect/:schema/:id',
      name: 'redirect',
      component: loadComponent('Redirect.vue'),
      meta: { autoTransition: true }
    },
    {
      path: '/cancel',
      name: 'cancel',
      component: loadComponent('Cancel.vue'),
      meta: { requiresAuth: true }
    },
    {
      path: '/maintenance',
      name: 'maintenance',
      component: loadComponent('Maintenance.vue'),
      meta: { autoTransition: true }
    },
    {
      path: '/error',
      name: 'error',
      component: loadComponent('Error.vue'),
      meta: { autoTransition: true }
    },
    {
      path: '/notfound',
      name: 'notfound',
      component: loadComponent('Notfound.vue'),
      meta: { autoTransition: true }
    },
    {
      path: '**',
      redirect: { name: 'notfound' }
    }
  ],
  scrollBehavior (to, from, savedPosition) {
    // ブラウザバック or 戻るボタンを押したときは表示するページの描画後に前回の表示位置にスクロールさせる.
    // 上記以外のページ遷移時にはトップを表示させる
    return new Promise(resolve => {
      if (savedPosition) {
        const isProcessingWatch = store.watch(() => store.getters.isProcessing, (newValue, oldValue) => {
          if (newValue === false && oldValue === true) {
            // 処理中 => 完了になったとき監視をやめて前回の表示位置を返却する
            unwatch()
            resolve(savedPosition)
          }
        })
        const unwatch = () => isProcessingWatch()
      } else {
        resolve({ x: 0, y: 0 })
      }
    })
  }
})

/**
 * viewsのファイルをロード
 * @param {String} name viewsのファイル名
 * @return {Object} 遅延ロードしたコンポーネント
 */
function loadComponent (name) {
  return () => import(/* webpackChunkName: "view-[request]" */ `@/views/${name}`)
}

/**
 * To begin with, we confirm wheter or not log in the account.
 * If user doesn't log in the account, go to about page.
 * In case of the page of making an account, go to /signup.
 */
router.beforeEach((to, from, next) => {
  const autoTransition = to.matched.some(record => record.meta.autoTransition)
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth)

  // 別画面に遷移する時、processingを表示状態にする
  if (to.name !== from.name) store.commit('setProcessing', true)

  // 認証後の遷移先として現在のURLを保存する
  const redirectURL = store.getters.redirectPath
  if (!redirectURL) store.commit('setRedirectURL', to.path)

  // 判定用のデータ取得
  const auth = firebase.auth().currentUser
  const uid = auth ? auth.uid : null
  const isAuthProcessing = store.getters.isAuthProcessing

  // 認証状況に応じた分岐処理
  // 初回アクセス時、onAuthの処理が終わる前にrouterが動くため、初回処理が終わるまで遷移をループさせる
  if (isAuthProcessing || autoTransition) {
    // Auth処理中の場合は遷移をキャンセルさせる
    autoTransition ? next() : next(false)
  } else if (uid === null) {
    requiresAuth ? next({ name: 'top' }) : next()
  } else {
    !requiresAuth ? next({ name: 'list' }) : next()
  }
})

export default router
