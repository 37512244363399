import { firebase } from '@/firebase'
import router from '@/router'

// functionsがあるリージョン
const REGION = 'asia-northeast1'

const state = {
}

const getters = {
}

const mutations = {
}

const actions = {
  /**
   * メールを送信
   * @param {Object} payload 引数の情報
   * @param {String} payload.subject メールのタイトル
   * @param {String} payload.content メールの本文（改行コード含む）
   */
  async sendEmail ({ commit }, payload) {
    try {
      const send = firebase.functions(REGION).httpsCallable('sendEmail')
      await send({
        subject: payload.subject,
        content: payload.content
      })
    } catch {
      router.push({ name: 'error' })
    }
  },
  /**
   * Facebookアカウントの文字列IDから数字IDを取得
   * @param {Object} payload 引数の情報
   * @param {String} payload.url アクセス先URL
   * @return {String} Facebookアカウントの数字ID
   */
  async getFacebookInfo ({ commit }, payload) {
    try {
      const info = firebase.functions(REGION).httpsCallable('getFacebookInfo')
      const result = await info({
        url: payload.url
      })
      return result.data
    } catch {
      router.push({ name: 'error' })
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
